import React from "react";

const Benefits = () => {
    return (
        <section className="section bg-white has-mask" id="usecase">
            <div className="nk-mask bg-pattern-dot-sm bg-blend-around"></div>
            <div className="container">
                <div className="section-head">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-9 col-xl-8 col-xxl-7">
                            <div className="badge text-bg-primary-soft-outline text-uppercase text-tracking-1 rounded-pill px-3 py-2 mb-3">What You Get</div>
                            <h2 className="title h1">Gains with GetLead.ai</h2>
                            <p className="lead px-xl-6">Numbers, advantages, and benefits of adding artificial intelligence to your sales process.</p>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row justify-content-center">
                        <div className="col-xxl-11">
                            <div className="row gy-6 gx-5 text-center">
                                <div className="col-sm-6 col-lg-4">
                                    <div className="h-100 px-xl-4 px-xxl-5">
                                        <div className="feature">
                                            <div className="feature-media">
                                                <div className="media media-middle media-xl media-border text-bg-danger-soft-outline rounded-3">
                                                    <img className="h-2rem" src="images/icon/articles.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="feature-text">
                                                <h3 className="title text-2xl !font-black">Leads Boosted by 60%</h3>
                                                <p>With our AI-powered Chatbot, experience a remarkable increase of up to 60% in social media lead generation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="h-100 px-xl-4 px-xxl-5">
                                        <div className="feature">
                                            <div className="feature-media">
                                                <div className="media media-middle media-xl media-border text-bg-success-soft-outline rounded-3">
                                                    <img className="h-2rem" src="images/icon/product-discription.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="feature-text">
                                                <h3 className="title text-2xl !font-black">High Quality Leads</h3>
                                                <p>Witness a phenomenal 45% rise in conversion rates as our Chatbot engages and nurtures leads, turning prospects into loyal customers.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="h-100 px-xl-4 px-xxl-5">
                                        <div className="feature">
                                            <div className="feature-media">
                                                <div className="media media-middle media-xl media-border text-bg-purple-soft-outline rounded-3">
                                                    <img className="h-2rem" src="images/icon/hand-mic.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="feature-text">
                                                <h3 className="title text-2xl !font-black">Faster Lead Collection</h3>
                                                <p>Save valuable time and resources with our AI Chatbot, collecting leads three times faster than traditional methods.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="h-100 px-xl-4 px-xxl-5">
                                        <div className="feature">
                                            <div className="feature-media">
                                                <div className="media media-middle media-xl media-border text-bg-info-soft-outline rounded-3">
                                                    <img className="h-2rem" src="images/icon/praying-hand.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="feature-text">
                                                <h3 className="title text-2xl !font-black">24/7 Availability</h3>
                                                <p>Ensure round-the-clock customer engagement with our AI Chatbot, available 24/7. Provide instant support and capture leads at any hour.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="h-100 px-xl-4 px-xxl-5">
                                        <div className="feature">
                                            <div className="feature-media">
                                                <div className="media media-middle media-xl media-border text-bg-primary-soft-outline rounded-3">
                                                    <img className="h-2rem" src="images/icon/stock-up.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="feature-text">
                                                <h3 className="title text-2xl !font-black">Save Time</h3>
                                                <p> Streamline your sales processes with AI Technology and reclaim precious time to focus on other aspects of your business. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="h-100 px-xl-4 px-xxl-5">
                                        <div className="feature">
                                            <div className="feature-media">
                                                <div className="media media-middle media-xl media-border text-bg-indigo-soft-outline rounded-3">
                                                    <img className="h-2rem" src="images/icon/website.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="feature-text">
                                                <h3 className="title text-2xl !font-black">Ad Spend Savings</h3>
                                                <p>Experience cost-effective lead generation with our AI Chatbot. Save up to 40% on marketing expenditures while achieving exceptional results.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits;