import React from "react";
import SinglePricingCard from "../../components/SinglePricingCard";

const Pricing = () => {
    return (
        <section className="section section-bottom-0 bg-light" id="pricing">
            <div className="container">
                <div className="section-head">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-9 col-xl-8 col-xxl-6">
                            <div className="badge text-bg-primary-soft-outline text-uppercase text-tracking-1 rounded-pill px-3 py-2 mb-3">Pricing</div>
                            <h2 className="title h1">Pay As You Go</h2>
                            <p className="lead">Pay only for the leads you get.<br/>Let’s grow together.</p>
                        </div>
                    </div>
                </div>
                <div className="section-content pb-20">
                    <SinglePricingCard />
                </div>
            </div>
        </section>
    )
}

export default Pricing;