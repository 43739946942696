import React from "react";
import Footer from "../components/footer";
import Navbar from "../components/navbar";

const Layout = ({ children }) => {
    return (
        <div className="nk-app-root bg-darker">
            <Navbar showHero={window.location.pathname == "/" || window.location.pathname == ""} />
            <main className="nk-pages relative">
                {children}
            </main>
            <Footer />
        </div>
    )
}

export default Layout;