import React from "react";
import HeroSection from "../../views/home/HeroSection";

const Navbar = ({ showHero = false }) => {
	return (
			<header id="header" className="nk-header bg-darker is-dark has-mask">
					<div className="nk-mask bg-pattern-dot-white-sm bg-blend-bottom"></div>
					<div className="nk-header-main nk-menu-main is-transparent will-shrink on-dark ignore-mask">
							<div className="container">
									<div className="nk-header-wrap flex items-center gap-6">
											<div className="nk-header-logo">
													<a href="#" className="logo-link">
														<div className="flex items-center gap-3">
															<img className="h-10" src="images/logo.png" srcset="images/logo2x.png 2x" alt="" />
															<div className="text-2xl">GET<b>LEAD</b>.AI</div>
														</div>
													</a>
											</div>
											<div className="nk-header-toggle">
												<button className="btn btn-light btn-icon header-menu-toggle">
													<em className="icon ni ni-menu"></em>
												</button>
											</div>
											<nav className="nk-header-menu nk-menu">
													<ul className="nk-menu-list me-auto">
															<li className="nk-menu-item">
																<a href="/#header" className="nk-menu-link">
																	<span className="nk-menu-text !text-base">Home</span>
																</a>
															</li>
															<li className="nk-menu-item">
																<a href="/#howto" className="nk-menu-link">
																	<span className="nk-menu-text !text-base">How To</span>
																</a>
															</li>
															<li className="nk-menu-item">
																<a href="/#usecase" className="nk-menu-link">
																	<span className="nk-menu-text !text-base">Use Cases</span>
																</a>
															</li>
															<li className="nk-menu-item">
																<a href="/#pricing" className="nk-menu-link">
																	<span className="nk-menu-text !text-base">Pricing</span>
																</a>
															</li>
													</ul>
													<ul className="nk-menu-buttons flex-lg-row-reverse">
															<li><a href="https://app.getlead.ai" className="btn btn-primary rounded-pill !font-bold !text-sm py-2">Get Started &mdash; It's Free!</a></li>
													</ul>
											</nav>
									</div>
							</div>
					</div>
					
					{showHero && <HeroSection />}
			</header>
	);
}

export default Navbar;