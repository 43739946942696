import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from "./home";
import Privacy from "./privacy";
import Terms from "./terms";

const RoutesProvider = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
        </Routes>
    )
}

export default RoutesProvider;