import React from "react";

const CallToAction = () => {
    return (
        <section className="section bg-light section-0 has-mask mb-6 lg:mb-0">
            <div className="nk-mask bg-darker top-50"></div>
            <div className="container container-xl">
                <div className="section-wrap bg-dark is-dark rounded-4 has-shape overflow-hidden">
                    <div className="nk-shape bg-shape-blur-b start-50 top-50 translate-middle"></div>
                    <div className="section-content p-5 p-sm-5 p-xl-7">
                        <div className="row justify-content-center align-items-center g-5">
                            <div className="col-xl-6 col-lg-6">
                                <div className="block-text text-center">
                                    <h6 className="text-primary text-4xl lg:text-5xl !font-[900]">Boost Your Sales Now!</h6>
                                    <h2 className="title mt-4 text-lg">Start generating high quality leads right now.</h2>
                                    <p>Create an account and set up your automatic AI lead generation chatbot in just a few minutes. Start growing today with GetLead.ai. Let's grow together.</p>
                                    <ul className="list list-row gx-3 gy-0 mt-4">
                                        <li><em className="icon fs-5 ni ni-check-circle-fill text-info"></em><span>No credit card required</span></li>
                                        <li><em className="icon fs-5 ni ni-check-circle-fill text-info"></em><span>Cancel anytime</span></li>
                                    </ul>
                                    <ul className="btn-list btn-list-inline gy-0">
                                        <li><a href="https://app.getlead.ai" className="btn btn-lg btn-primary rounded-pill !text-base py-2.5 lg:py-3 lg:px-8 font-[900]"><span>Create a Free Account</span> &nbsp; <em className="icon ni ni-arrow-long-right"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-6 align-self-end">
                                <div className="bg-white rounded-top-4">
                                    <div className="rounded-top-4 bg-gradient-primary bg-opacity-70 p-5 pb-0 mb-n4 mb-sm-n5 mb-xl-n7">
                                        <div className="block-gfx">
                                            <img className="w-100 rounded-top-3 shadow-sm" src="images/gfx/process/c.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CallToAction;