import React from "react";
import { TypeAnimation } from "react-type-animation";

const HeroSection = () => {
    return (
        <div className="nk-hero py-xl-5 overflow-hidden has-shape !min-h-[100vh] w-full flex items-center justify-center -mt-10 lg:-mt-8">
            <div className="nk-shape bg-shape-blur-b mt-n5 start-50 top-50 translate-middle"></div>
                <div className="container">
                    <div className="row justify-content-center text-center">
                    <div className="col-lg-11 col-xl-10 col-xxl-9">
                            <div className="nk-hero-content py-5 py-lg-6">
                                <h1 className="title mb-3 mb-4 display-6">
                                    AI Lead Generation for&nbsp;
                                        <div className="text-gradient-primary inline-block lg:block">
                                            <TypeAnimation
                                                sequence={[
                                                    'Social Networks',
                                                    2500,
                                                    'Websites',
                                                    2500,
                                                    'Facebook',
                                                    2500,
                                                    'Instagram',
                                                    2500
                                                ]}
                                                wrapper="span"
                                                speed={30}
                                                // style={{ fontSize: '2em', display: 'inline-block' }}
                                                repeat={Infinity}
                                            />
                                    </div>
                                </h1>
                                <p className="lead px-md-8 px-lg-6 px-xxl-12 mb-4 mb-lg-5 !text-lg">Revolutionize your lead generation process with the power of AI. Experience success now with GetLead.ai &mdash; the ultimate automation solution for your growth!</p>
                                <ul className="btn-list btn-list-inline mt-6">
                                    <li><a href="https://app.getlead.ai" className="btn btn-primary btn-lg rounded-pill !font-bold !text-sm"><span>Get Started &mdash; It's Free!</span></a></li>
                                </ul>
                                <p className="sub-text mt-2">No credit card required</p>
                            </div>
                            <div className="nk-hero-gfx position-relative">
                                {/* <img className="w-100 rounded-4" src="images/gfx/banner/c.jpg" alt="" /> */}
                                <div className="animate-1 d-none d-md-block position-absolute top-0 end-100 me-5 me-lg-8 me-xl-12 mt-n3 !-mt-64 !mr-20">
                                    <div className="media media-2xl rounded-xl mx-auto">
                                        <img src="images/facebook.png" alt="" />
                                    </div>
                                </div>
                                <div className="animate-3 d-none d-md-block position-absolute top-50 end-100 me-3 me-lg-4 mt-n5 !-mr-4">
                                    <div className="media media-2xl rounded-xl mx-auto">
                                        <img src="images/instagram.png" alt="" />
                                    </div>
                                </div>
                                <div className="animate-2 d-none d-md-block position-absolute top-0 start-100 ms-5 ms-lg-7 ms-xl-10 mt-n7 !-mt-52">
                                    <div className="media media-2xl rounded-xl mx-auto">
                                        <img src="images/whatsapp.png" alt="" />
                                    </div>
                                </div>
                                <div className="animate-4 d-none d-md-block position-absolute top-50 start-100 ms-4 ms-lg-5 mt-n2 !-ml-8">
                                    <div className="media media-2xl rounded-xl mx-auto">
                                        <img src="images/website.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="nk-hero-content pb-6 pt-2 lg:pt-6">
                                    <h6 className="lead-text">Trusted by 2,000+ companies around the globe.</h6>
                                    <a href="https://followr.ai?utm_campaign=getlead&utm_medium=referral&utm_source=getlead" target="_blank">
                                        <div className="flex mt-4 gap-2 items-center justify-center w-full">
                                            <small>Powered by</small>
                                            <img src="images/followr.png" className="h-12" alt="" />
                                        </div>
                                    </a>
                                    {/* <ul className="d-flex flex-wrap justify-content-center pt-4 has-gap gy-3">
                                            <li className="px-3 px-sm-5">
                                                    <img className="h-2rem" src="images/brands/72-b-white.png" alt="" />
                                            </li>
                                            <li className="px-3 px-sm-5">
                                                    <img className="h-2rem" src="images/brands/72-c-white.png" alt="" />
                                            </li>
                                            <li className="px-3 px-sm-5">
                                                    <img className="h-2rem" src="images/brands/72-d-white.png" alt="" />
                                            </li>
                                            <li className="px-3 px-sm-5">
                                                    <img className="h-2rem" src="images/brands/72-e-white.png" alt="" />
                                            </li>
                                    </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default HeroSection;