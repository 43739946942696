import logo from './logo.svg';
import './App.css';
import Home from './views/home';
import Layout from './views/layout';
import RoutesProvider from './views/RoutesProvider';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';

function App() {

  const cookies = new Cookies();
  if (!cookies.get("land_url")) {
    cookies.set('land_url', window.location.href, { path: '/', domain: "getlead.ai" });
    cookies.set('land_url', window.location.href, { path: '/', domain: ".getlead.ai" });
    cookies.set('document_referrer', document.referrer, { path: '/', domain: "getlead.ai" });
    cookies.set('document_referrer', document.referrer, { path: '/', domain: ".getlead.ai" });
  }

  return (
    <BrowserRouter>
      <Layout>
        <RoutesProvider />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
