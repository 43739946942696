import React from "react";
import BoostSocialMedia from "./BoostSocialMedia";
import Steps from "./Steps";
import Benefits from "./Benefits";
import Pricing from "./Pricing";
import FAQs from "./FAQs";

const Home = () => {

    return (
        <>
            <BoostSocialMedia />
            <Steps />
            <Benefits />
            <Pricing />
            <FAQs />
        </>
    );
}

export default Home;