import React from "react";

const Steps = () => {
    return (
        <section className="section section-bottom-0 bg-white rounded-top-6" id="howto">
            <div className="container">
                <div className="section-head">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-9 col-xl-8 col-xxl-6">
                            <div className="badge text-bg-primary-soft-outline text-uppercase text-tracking-1 rounded-pill px-3 py-2 mb-3">Easy Set Up</div>
                            <h2 className="title text-3xl lg:text-4xl !font-[900]">Quick Onboarding, Real Results!</h2>
                            <p className="lead">Experience the efficiency of GetLead's quick onboarding and unleash the potential of your business. Power it Up!</p>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row gy-3 justify-content-center">
                        <div className="col-xxl-12">
                            <div className="bg-primary bg-opacity-10 p-5 p-lg-6 rounded-4">
                                <div className="row g-gs flex-lg-row-reverse justify-content-between align-items-center">
                                    <div className="col-lg-6 col-xl-5">
                                        <div className="rounded-4 pb-0">
                                            <div className="block-gfx text-right">
                                                <img className="max-h-[200px] lg:max-h-[300px] h-full rounded-top-3 mx-auto" src="images/steps-create-account.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xxl-5">
                                        <div className="block-text pe-xl-5">
                                            <h1 className="text-5xl text-primary mb-4">1.</h1>
                                            <h3 className="title text-2xl !font-[900]">Create Your Account</h3>
                                            <p>With a single click, create your GetLead.ai account and unlock the power of seamless lead generation and management. No credit card required.</p>
                                            <a href="https://app.getlead.ai" className="btn btn-primary btn-lg rounded-pill !font-bold !text-sm mt-5"><span>Create Account &mdash; It's Free!</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-12">
                            <div className="bg-warning bg-opacity-10 p-5 p-lg-6 rounded-4">
                                <div className="row g-gs flex-lg-row-reverse justify-content-between align-items-center">
                                    <div className="col-lg-6 col-xl-5">
                                        <div className="rounded-4 pb-0">
                                            <div className="block-gfx text-right">
                                                <img className="max-h-[200px] lg:max-h-[300px] h-full rounded-top-3 mx-auto" src="images/steps-configure-bot.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xxl-5">
                                        <div className="block-text pe-xl-5">
                                            <h1 className="text-5xl text-warning mb-4">2.</h1>
                                            <h3 className="title text-2xl !font-[900]">Configure Your Bot</h3>
                                            <p>Seamlessly connect your social networks and educate the bot about your company's specific needs. With this simple step, unlock a personalized experience that caters to your business requirements like never before!</p>
                                            <a href="#" className="btn btn-warning text-white btn-lg rounded-pill !font-bold !text-sm mt-5"><span>Configure Your Bot &mdash; It's Free!</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-12">
                            <div className="bg-info bg-opacity-10 p-5 p-lg-6 rounded-4">
                                <div className="row g-gs flex-lg-row-reverse justify-content-between align-items-center">
                                    <div className="col-lg-6 col-xl-5">
                                        <div className="rounded-4 pb-0">
                                            <div className="block-gfx text-right">
                                                <img className="max-h-[200px] lg:max-h-[300px] h-full rounded-top-3 mx-auto" src="images/steps-generate-leads.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xxl-5">
                                        <div className="block-text pe-xl-5">
                                            <h1 className="text-5xl text-info mb-4">3.</h1>
                                            <h3 className="title text-2xl !font-[900]">Generate High Quality Leads</h3>
                                            <p>That's it! Watch as the power of cutting-edge technology works its magic. Let GetLead.ai generate high-quality leads for your business, leading to an impressive boost in your sales!</p>
                                            <a href="#" className="btn btn-info text-white btn-lg rounded-pill !font-bold !text-sm mt-5"><span>Start Generating Leads &mdash; It's Free!</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Steps;