import React from "react";
import CallToAction from "./CallToAction";

const Footer = () => {
    return (
        <footer className="nk-footer">
            {(window.location.pathname == "/" || window.location.pathname == "") && <CallToAction />}
            <section className="section bg-darker is-dark">
                <div className="">
                    <div className="">
                        <div className="">
                            <div className="block-text flex flex-col justify-center items-center w-full">
                                <a href="#" className="logo-link mb-4">
                                    <div className="flex items-center gap-3">
                                        <img className="h-16 lg:h-20" src="images/logo.png" srcset="images/logo2x.png 2x" alt="" />
                                        <div className="text-4xl lg:text-5xl">GET<b>LEAD</b>.AI</div>
                                    </div>
                                </a>
                                <ul className="btn-list btn-list-inline g-1 mt-4">
                                    {/* <li><a className="link-base" href="#"><em className="icon !h-10 !text-3xl fs-3 ni ni-facebook-fill"></em></a></li> */}
                                    <li><a className="link-base" href="https://www.instagram.com/getlead.ai/" target="_blank"><em className="icon !h-10 !text-3xl fs-3 ni ni-instagram-round"></em></a></li>
                                    <li><a className="link-base" href="https://twitter.com/GetLead_ai" target="_blank"><em className="icon !h-10 !text-3xl fs-3 ni ni-twitter-round"></em></a></li>
                                    <li><a className="link-base" href="https://www.linkedin.com/company/getlead-ai/about/" target="_blank"><em className="icon !h-10 !text-3xl fs-3 ni ni-linkedin-round"></em></a></li>
                                    <li><a className="link-base" href="https://medium.com/@getlead-ai"><em className="icon !h-10 !text-3xl fs-3 ni ni-medium-round"></em></a></li>
                                </ul>
                                <a className="mt-2" href="mailto:hello@getlead.ai">hello@getlead.ai</a>
                            </div>
                        </div>
                        {/* <div className="col-xl-2 col-lg col-md-3 col-6">
                            <div className="wgs">
                                <h6 className="wgs-title">Tools</h6>
                                <ul className="list gy-2 list-link-base">
                                    <li><a className="link-base" href="#">Article Generator</a></li>
                                    <li><a className="link-base" href="#">Blog Ideas</a></li>
                                    <li><a className="link-base" href="#">Blog Intros</a></li>
                                    <li><a className="link-base" href="#">Blog Outlines</a></li>
                                    <li><a className="link-base" href="#">Product Description</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg col-md-3 col-6">
                            <div className="wgs">
                                <h6 className="wgs-title">Resources</h6>
                                <ul className="list gy-2 list-link-base">
                                    <li><a className="link-base" href="#">Facebook Group</a></li>
                                    <li><a className="link-base" href="#">Discord Community</a></li>
                                    <li><a className="link-base" href="#">Guide and Tutorials</a></li>
                                    <li><a className="link-base" href="#">Request API access</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg col-md-3 col-6">
                            <div className="wgs">
                                <h6 className="wgs-title">Company</h6>
                                <ul className="list gy-2 list-link-base">
                                    <li><a className="link-base" href="#">About us</a></li>
                                    <li><a className="link-base" href="#">Careers</a></li>
                                    <li><a className="link-base" href="#">Pricing</a></li>
                                    <li><a className="link-base" href="#">Contact Us</a></li>
                                    <li><a className="link-base" href="#">Wall of Love</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg col-md-3 col-6">
                            <div className="wgs">
                                <h6 className="wgs-title">Use Case</h6>
                                <ul className="list gy-2 list-link-base">
                                    <li><a className="link-base" href="#">AI Writer</a></li>
                                    <li><a className="link-base" href="#">AI Articel Writer</a></li>
                                    <li><a className="link-base" href="#">Content Generator</a></li>
                                    <li><a className="link-base" href="#">AI Content Writing</a></li>
                                    <li><a className="link-base" href="#">Content Rewriter</a></li>
                                    <li><a className="link-base" href="#">Blog Post Writer</a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <div className="bg-darker is-dark">
                <div className="container">
                    <hr className="border-opacity-25 border-white m-0" />
                    <div className="py-5">
                        <div className="row">
                            <div className="col-md">
                                <p className="mb-2 mb-md-0 text-center lg:!text-left">&copy; {new Date().getFullYear()} Reblox Solutions LLC.</p>
                            </div>
                            <div className="col-md">
                                <ul className="list list-row gx-4 justify-content-center justify-content-md-end">
                                    <li><a href="/terms" className="link-primary">Terms</a></li>
                                    <li><a href="/privacy" className="link-primary">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;