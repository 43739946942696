import React from "react";

const faqs = [
    { 
        question: "Is the AI Chatbot user-friendly and easy to set up?",
        answer: "Yes, our AI Chatbot is designed with user-friendliness in mind. The setup process is straightforward, and we provide step-by-step guidance to ensure a seamless onboarding experience. You don't need extensive technical expertise to get your AI Chatbot up and running for lead acquisition on social media platforms.",
    },
    { 
        question: "Which social media platforms can the AI Chatbot connect with? ",
        answer: "Our AI Chatbot seamlessly integrates with Facebook and Instagram, and we are dedicated to including your users' preferred platforms. This way, you can effectively engage with new customers through their favorite communication channels, maximizing your reach and impact.",
    },
    { 
        question: "Can the AI Chatbot be customized?",
        answer: "Absolutely! Our AI Chatbot is fully customizable, allowing you to tailor its interactions to your specific needs. You can configure it to request various types of data, skip data collection, or prompt users for different actions, providing a personalized and user-friendly experience. Flexibility is at your fingertips!",
    },
    { 
        question: "How does the AI Chatbot excel as a virtual sales representative?",
        answer: "During the onboarding process, our AI Chatbot undergoes a learning phase where it comprehends the nuances of your business, industry, products, and services. This understanding empowers the Chatbot to provide personalized and contextually relevant interactions to your audience. As a result, customers receive a tailored commercial experience that resonates with their needs, leading to higher engagement, satisfaction, and ultimately, increased conversions.",
    },
    {
        question: "Is there a limit on number of messages that can be sent?",
        answer: "No, there are no limits on number of messages. Please, check the above pricing plans to check conversations limits of each plan."
    },
    {
        question: "What languages does the AI Chatbot support?",
        answer: "The AI Chatbot supports more than 30 languages: English, Spanish, French, German, Italian, Portuguese, Dutch, Russian, Japanese, Korean, Chinese, Chinese, Arabic, Hindi, Filipino, Indonesian, Malay, Vietnamese, Thai, Turkish, Swedish, Norwegian, Danish, Polish, Czech, Slovak, Hungarian, Romanian, Bulgarian, Serbian, Croatian, Greek, Hebrew."
    },
    { 
        question: "Can leads be exported to various formats for easy integration with any CRM?",
        answer: "Definitely! Our AI Chatbot allows you to export leads to CSV or any preferred format, enabling seamless integration with your CRM of choice. This flexibility ensures smooth lead management, empowering your team to nurture and maximize every potential opportunity.",
    },
];

const FAQs = () => {

    return (
        <section className="section bg-light">
            <div className="container">
                <div className="section-head">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-8">
                            <h2 className="title !text-4xl !font-[900]">Frequently Asked Questions</h2>
                            <p className="lead">If you have any questions not answered in the FAQ, please do not hesitate to contact us.</p>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row g-gs justify-content-center">
                        <div className="col-xl-9 col-xxl-8">
                            <div className="accordion accordion-separated accordion-plus-minus" id="faq-1">
                                {faqs.map((item, key) => (
                                    <div className="accordion-item" key={key}>
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#faq-1-${key}`}>{item?.question}</button>
                                        </h2>
                                        <div id={`faq-1-${key}`} className="accordion-collapse collapse !visible" data-bs-parent="#faq-1">
                                            <div className="accordion-body">{item?.answer}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQs;