import React from "react";
import { HiOutlineChatBubbleLeftRight, HiOutlineRocketLaunch, HiOutlineUserGroup, HiOutlineUserPlus } from "react-icons/hi2";
import { TbRobot } from "react-icons/tb";

const BoostSocialMedia = () => {
    return (
        <section className="section bg-darker is-dark section-top-0 has-shape mt-4">
            <div className="nk-shape bg-shape-blur-a start-50 top-50 translate-middle"></div>
            <div className="container">
                <div className="section-head">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-9 col-xl-6 col-xxl-6">
                            <h1 className="title text-3xl lg:text-4xl !font-[900]">Boost Your Social Media</h1>
                            <p className="lead">Let our AI Chatbot be in charge of social media lead generation. Engage, convert, and conquer with GetLead's unstoppable capabilities:</p>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row g-gs">
                        <div className="col-md-6 col-xl-4">
                            <div className="card rounded-4 border-0 h-100">
                                <div className="card-body">
                                    <div className="feature">
                                        <div className="feature-media">
                                            <div className="media media-middle media-xl text-info bg-info bg-opacity-20 rounded-3">
                                                <HiOutlineRocketLaunch className="text-3xl" />
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title text-xl !font-[900]">Boost Your Social Media</h4>
                                            <p>Let our AI Chatbot be in charge of social media lead generation. Engage, convert, and conquer with GetLead's unstoppable capabilities.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="card rounded-4 border-0 h-100">
                                <div className="card-body">
                                    <div className="feature">
                                        <div className="feature-media">
                                            <div className="media media-middle media-xl text-primary bg-primary bg-opacity-20 rounded-3">
                                                <HiOutlineUserGroup className="text-3xl" />
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title text-xl !font-[900]">Seamless Lead Management</h4>
                                            <p>Effortlessly collect and manage leads with our AI Chatbot's features optimizing your marketing efforts.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="card rounded-4 border-0 h-100">
                                <div className="card-body">
                                    <div className="feature">
                                        <div className="feature-media">
                                            <div className="media media-middle media-xl text-indigo bg-indigo bg-opacity-20 rounded-3">
                                                <TbRobot className="text-3xl" />
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title text-xl !font-[900]">Customizable AI Chatbots</h4>
                                            <p>Personalize it with your brand's identity, audience and business to deliver a cohesive sales support. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="card rounded-4 border-0 h-100">
                                <div className="card-body">
                                    <div className="feature">
                                        <div className="feature-media">
                                            <div className="media media-middle media-xl text-success bg-success bg-opacity-20 rounded-3">
                                                <em className="icon ni ni-swap-alt"></em>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title text-xl !font-[900]">Unlock CRM Capabilities</h4>
                                            <p>Explore the power of our AI Chatbot to track leads, store user data, and access basic CRM tools for streamlined lead nurturing.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="card rounded-4 border-0 h-100">
                                <div className="card-body">
                                    <div className="feature">
                                        <div className="feature-media">
                                            <div className="media media-middle media-xl text-danger bg-danger bg-opacity-20 rounded-3">
                                                <div className=" d-flex align-items-end">
                                                    <HiOutlineUserPlus className="text-3xl" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title text-xl !font-[900]">Enhanced User Experience</h4>
                                            <p>With intelligent tracking our AI ensures smooth and personalized user interactions for both users and customers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="card rounded-4 border-0 h-100">
                                <div className="card-body">
                                    <div className="feature">
                                        <div className="feature-media">
                                            <div className="media media-middle media-xl text-warning bg-warning bg-opacity-20 rounded-3">
                                                <HiOutlineChatBubbleLeftRight className="text-3xl" />
                                            </div>
                                        </div>
                                        <div className="feature-text">
                                            <h4 className="title text-xl !font-[900]">Multi-Conversation Management</h4>
                                            <p>Handle multiple interactions within the chat interface with ease. From past conversations to new inquiries, manage all in one place.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BoostSocialMedia;