import React from "react";
import { HiCheckCircle } from "react-icons/hi2";

function SinglePricingCard() {

  return (
    <div className="flex flex-col lg:flex-row overflow-hidden w-full bg-white rounded-2xl shadow-2xl">
      <div className="p-6">
        <div className="text-3xl font-bold text-gray-900">No upfront costs</div>
        <div className="mt-2 leading-relaxed" color="text.secondary">
          Discover the power of GetLead.ai - suscribe and start generating leads with confidence, knowing there are no upfront costs!
        </div>

        <div className="flex items-center mt-6">
          <div className="font-medium" color="text.secondary">
            INCLUDED FEATURES
          </div>
          <hr className="flex-auto ml-2" />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 mt-4">
          <div className="flex items-center">
            <HiCheckCircle className="text-primary" />
            <div className="ml-8">Receive <b className='text-primary'>10 FREE LEADS</b> to start.</div>
          </div>
          <div className="flex items-center">
            <HiCheckCircle className="text-primary" />
            <div className="ml-8">Launch without commitments.</div>
          </div>
          <div className="flex items-center">
            <HiCheckCircle className="text-primary" />
            <div className="ml-8">Pay only for acquired leads.</div>
          </div>
          <div className="flex items-center">
            <HiCheckCircle className="text-primary" />
            <div className="ml-8">Set monthly spending limit.</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center p-6 pb-24 w-full max-w-[400px] bg-darker is-dark">
        <div className="flex items-center whitespace-nowrap">
          <div className="text-7xl font-extrabold tracking-tight text-white">
            $0.49
          </div>
          <div className="ml-2 text-2xl font-semibold mt-2" color="text.secondary">
            USD<br/>
            <small className='-mt-1 block'>per lead</small>
          </div>
        </div>
        <div className="font-medium text-center mt-4" color="text.secondary">
          No commitments,
          <br />
          pay only for what you use.
        </div>
        <a href="https://app.getlead.ai" className="btn btn-primary rounded-pill !font-bold !text-base py-3 px-0 w-full mt-6">
          <span>Get Started &mdash; It's Free!</span>
        </a>
      </div>
    </div>
  );
}

export default SinglePricingCard;
